import { match as aboutUs } from "../../../src/params/aboutUs.ts";
import { match as caseStudies } from "../../../src/params/caseStudies.ts";
import { match as howToApply } from "../../../src/params/howToApply.ts";
import { match as media } from "../../../src/params/media.ts";
import { match as news } from "../../../src/params/news.ts";
import { match as pages } from "../../../src/params/pages.ts";
import { match as products } from "../../../src/params/products.ts";
import { match as search } from "../../../src/params/search.ts";
import { match as support } from "../../../src/params/support.ts";
import { match as videos } from "../../../src/params/videos.ts";
import { match as whereToBuy } from "../../../src/params/whereToBuy.ts";

export const matchers = { aboutUs, caseStudies, howToApply, media, news, pages, products, search, support, videos, whereToBuy };