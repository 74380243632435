import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [0];

export const dictionary = {
		"/[[site]]/admin": [~13],
		"/[[site]]/[whereToBuy=whereToBuy]": [~12],
		"/[[site]]/[support=support]": [~11],
		"/[[site]]/[search=search]": [~10],
		"/[[site]]/[media=media]": [~5],
		"/[[site]]/[aboutUs=aboutUs]": [~3],
		"/[[site]]/[products=products]/[slug]": [~8],
		"/[[site]]/[pages=pages]/[slug]": [~7],
		"/[[site]]/[news=news]/[slug]": [~6],
		"/[[site]]/[caseStudies=caseStudies]/[slug]": [~4],
		"/[[site]]/[products=products]/[slug]/[sub]": [~9],
		"/[[site]]": [~2]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';