import { type Reroute } from '@sveltejs/kit';

const commercialRegex = /^(commercial|commerciale|commerciaux|comerciales|comercial)$/;
const langRegex = /^(it|fr|es|pt)$/;

/**
 * Combines /commercial/lang into /commercial-lang/ for the purpose of routing
 * This assumes that the commercial slug and lang slug always come in the same order
 * @param URL
 * @returns string
 */
export const reroute: Reroute = ({ url }) => {

  const [firstSlug, secondSlug, ...rest] = url.pathname.split('/').filter(Boolean);
  let path = url.pathname;

  if (firstSlug && commercialRegex.test(firstSlug)) {
    if (secondSlug && langRegex.test(secondSlug)) {
      path = `/${firstSlug}-${secondSlug}` + customJoin(rest);
    } else if (secondSlug) {
      path = `/${firstSlug}-/${secondSlug}` + customJoin(rest);
    } else {
      path = `/${firstSlug}-`;
    }
  } else {
    if (firstSlug && langRegex.test(firstSlug)) {
      if (secondSlug) {
        path = `/-${firstSlug}/${secondSlug}` + customJoin(rest);
      } else {
        path = `/-${firstSlug}`;
      }
    }
  }

  return path;
};

const customJoin = (rest: string[]) => {
  return rest.length ? '/' + rest.join('/') : '';
}